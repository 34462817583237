import type { ServiceCardItem } from "@components/ServiceCardItem.js";
import brackets from "@core/assets/icons/brackets.svg?raw";
import digi from "@core/assets/icons/digi.svg?raw";
import integration from "@core/assets/icons/integration.svg?raw";
import pc from "@core/assets/icons/pc.svg?raw";
import rentIt from "@core/assets/icons/rent-it.svg?raw";
import rescue from "@core/assets/icons/rescue.svg?raw";
import type { Tenant } from "@core/schema/Tenant.js";
import { getDigitalizationAndAutomationPath } from "@core/schema/paths/getDigitalizationAndAutomationPath.js";
import { getHireItTeamPath } from "@core/schema/paths/getHireItTeamPath.js";
import { getIntegrationsPath } from "@core/schema/paths/getIntegrationsPath.js";
import { getKiosksPath } from "@core/schema/paths/getKiosksPath.js";
import { getProjectRescuePath } from "@core/schema/paths/getProjectRescuePath.js";
import { getWebAppsPath } from "@core/schema/paths/getWebAppsPath.js";

export function getServices(tenant: Tenant): ServiceCardItem<true>[] {
	return [
		{
			title: "Vývoj webových aplikací",
			iconName: brackets,
			show: true,
			href: getWebAppsPath(tenant),
		},
		{
			title: "Pronájem IT týmu",
			iconName: rentIt,
			href: getHireItTeamPath(tenant),
			show: true,
		},
		{
			title: "Záchrana projektu",
			iconName: rescue,
			href: getProjectRescuePath(tenant),
			show: true,
		},
		{
			title: "Kiosková řešení",
			iconName: pc,
			show: true,
			href: getKiosksPath(tenant),
		},
		{
			title: "Řešení pro digitalizaci firem",
			iconName: digi,
			show: true,
			href: getDigitalizationAndAutomationPath(tenant),
		},
		{
			title: "Integrace s dalšími systémy",
			iconName: integration,
			show: true,
			href: getIntegrationsPath(tenant),
		},
	];
}
